import React from "react"
import { Button, Checkbox, Icon, Modal, Alert } from "antd"
import { injectIntl } from "gatsby-plugin-intl"

export class DatasetDeleteModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      check1: false,
      check2: false,
      check3: false,
      showAlert: false,
    }
  }

  componentDidMount() {
    this._resetChecks()
  }

  _resetChecks() {
    this.setState({
      check1: false,
      check2: false,
      check3: false,
    })
  }
  _onClickOk() {
    this._resetChecks()
    this.props.onOk()
  }

  _onClickCancel() {
    this._resetChecks()
    this.props.onCancel()
  }

  render() {
    const { visible } = this.props
    return (
      <Modal visible={visible} footer={null} closable={null}>
        <div className="dataset-delete-modal-container">
          <div className="dataset-delete-modal-header-container">
            <div className="dataset-delete-modal-header-circle">
              <Icon
                type="delete"
                style={{
                  color: "#d0011b",
                  fontSize: "40px",
                  textAlign: "center",
                }}
              />
            </div>
            <p className="dataset-delete-modal-header-content">
              {this.props.intl.formatMessage({
                id: "dataset-delete-modal-header",
              })}
            </p>
          </div>
          {this.state.showAlert ? (
            <Alert
              message={this.props.intl.formatMessage({
                id: "dataset-delete-modal-confirmation",
              })}
              type="error"
              showIcon
              style={{ marginBottom: "1em" }}
            />
          ) : null}
          <div className="dataset-delete-modal-content">
            <p>
              {this.props.intl.formatMessage({
                id: "dataset-delete-modal-content-question",
              })}
            </p>
            <p style={{ fontSize: "1em" }}>
              {this.props.intl.formatMessage({
                id: "dataset-delete-modal-content-advice",
              })}
            </p>
          </div>
          <div
            className="checks"
            style={{ marginTop: "-1em", marginBottom: "2em" }}
          >
            {["check1", "check2", "check3"].map(check => (
              <div key={check}>
                <Checkbox
                  checked={this.state[check]}
                  style={{
                    color: this.state.showAlert ? "red" : "#868D96",
                    fontSize: "12px",
                  }}
                  onChange={() =>
                    this.setState({ [check]: !this.state[check] })
                  }
                >
                  {this.props.intl.formatMessage({
                    id: `dataset-delete-modal-${check}`,
                  })}
                </Checkbox>
              </div>
            ))}
          </div>
          <div className="dataset-delete-modal-buttons">
            <Button
              className="knolar-button back"
              onClick={() => this._onClickCancel()}
            >
              {this.props.intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              className="knolar-button"
              type="primary"
              onClick={() =>
                this.state.check1 && this.state.check2 && this.state.check3
                  ? this._onClickOk()
                  : this.setState({ showAlert: true })
              }
            >
              {this.props.intl.formatMessage({
                id: "delete-dataset",
              })}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default injectIntl(DatasetDeleteModal)
