import React from "react"
import { Icon } from "antd"
import deleteIconDisabled from "../../assets/images/ingests/ic_delete.png"
import deleteIcon from "../../assets/images/ingests/ic-delete-on.png"

export default class DatasetActionsCell extends React.Component {
  static executeFunction(func) {
    if (typeof func === "function") {
      func()
    }
  }

  constructor(props) {
    super(props)

    this._onDeleteClick = this._onDeleteClick.bind(this)
    this._onStatusChange = this._onStatusChange.bind(this)
  }

  _onDeleteClick() {
    if (!this.props.deleteDisabled) {
      DatasetActionsCell.executeFunction(this.props.onDeleteClick)
    }
  }

  _onStatusChange() {
    if (!this.props.statusChangeDisabled) {
      DatasetActionsCell.executeFunction(this.props.onStatusChange)
    }
  }

  render() {
    return (
      <div className="dataset-actions" style={{ justifyContent: "center" }}>
        <div className="dataset-actions__button_wrapper">
          <button
            disabled={this.props.deleteDisabled}
            className="button-no-styles dataset-actions__button"
            onClick={this._onDeleteClick}
          >
            <img
              size="large"
              style={{ fontSize: "1.25rem" }}
              className={`dataset-actions__button__delete ${
                this.props.deleteDisabled
                  ? "dataset-actions__button--disabled"
                  : ""
              }`}
              src={this.props.deleteDisabled ? deleteIconDisabled : deleteIcon}
            />
          </button>
        </div>
      </div>
    )
  }
}
