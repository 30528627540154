import React from "react"
import { Button, Collapse, Col, Input, message, Row, Spin } from "antd"
import moment from "moment-timezone"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import withAuth from "../../components/withAuthHoc"
import Template from "../../layouts/base"
import { checkPermissions } from "../../services/auth/permissions/permissions"
import { getDatasets, getModels, deleteDataset } from "../../services/dataset"
import DatasetDeleteModal from "../../components/dataset/datasetDeleteModal"
import icModel from "../../assets/images/datasets/ic_model.png"
import icDataset from "../../assets/images/datasets/ic_dataset.png"
import icMonitor from "../../assets/images/datasets/ic_step_monitor.png"
import DatasetTable from "../../components/dataset/datasetTable"

const { Search } = Input
const { Panel } = Collapse

const ONE_MIN_IN_MS = 60000

class Datasets extends React.Component {
  static datasetUrl() {
    return `${process.env.GATSBY_CONF_API_URL}/dataset/`
  }

  static _isArrayWithValues(array) {
    return Array.isArray(array) && array.length > 0
  }

  constructor(props) {
    super(props)

    this.state = {
      creationSteps: {
        dataset: {
          title: "dataset-step-create-title",
          description: "dataset-step-create-description",
          icon: icDataset,
        },
        model: {
          title: "model-step-create-title",
          description: "model-step-create-description",
          icon: icModel,
        },
        monitor: {
          title: "monitor-step-title",
          description: "monitor-step-description",
          icon: icMonitor,
        },
      },
      datasets: [],
      models: [],
      filteredDatasets: null,
      // realTimeExtractors: null,
      loading: true,
      lastUpdate: null,
      searchValue: "",
      deleteModalVisible: false,
      datasetToDelete: null,
    }

    this.interval = null
    this.controller = null

    this._onSearchInputChange = this._onSearchInputChange.bind(this)
    this._getEmptyMessage = this._getEmptyMessage.bind(this)
    this._onDeleteDatasetClick = this._onDeleteDatasetClick.bind(this)
    this._onDeleteCancel = this._onDeleteCancel.bind(this)
  }

  componentDidMount() {
    this._loadDatasets()
  }

  componentWillUnmount() {
    this._clearInterval()
  }

  _onDeleteDatasetClick(datasetToDelete) {
    this.setState({ deleteModalVisible: true, datasetToDelete })
  }

  _onDeleteCancel() {
    this.setState({ deleteModalVisible: false, datasetToDelete: null })
  }

  _onDeleteConfirm() {
    deleteDataset(this.state.datasetToDelete)
      .then(response => {
        if (response.message === "Dataset deleted successfully!.") {
          message.info(
            this.props.intl.formatMessage({
              id: "page-dataset-delete-dataset-ok",
            })
          )
          this._loadDatasets()
        } else {
          message.error(
            this.props.intl.formatMessage({
              id: "page-dataset-delete-dataset-ko",
            })
          )
        }
      })
      .catch(error =>
        message.error(
          `${this.props.intl.formatMessage({
            id: "page-dataset-delete-dataset-ko",
          })} Error: ${error}`
        )
      )
    this.setState({ deleteModalVisible: false, datasetToDelete: null })
  }

  _updateLastUpdateDate() {
    this.setState({ lastUpdate: moment().format("HH:mm:ss") })
  }

  _filterDatasets(datasets, searchValue) {
    let filteredDatasets
    if (searchValue) {
      // datasets = this._filterByDatasetTagAliasOrVersion(datasets, searchValue)
      filteredDatasets = datasets.filter(ds =>
        ds.dataset_name.toLowerCase().includes(searchValue.toLowerCase())
      )
    } else {
      filteredDatasets = datasets
    }
    this.setState({ filteredDatasets })
  }

  _onSearchInputChange(ev) {
    const searchValue = (ev.target.value || "").trimStart().trimEnd()

    this.setState({ searchValue })
    this._filterDatasets(this.state.datasets, searchValue)
  }

  _loadDatasetsPeriodically() {
    this._clearInterval()
    this.interval = setInterval(
      () => this._loadDatasetsInBackground(),
      ONE_MIN_IN_MS
    )
  }

  _loadDatasetsInBackground() {
    this.controller = new AbortController()
    const signal = this.controller.signal

    return getDatasets({ signal })
      .then(response => this._onLoadDatasets(response))
      .catch(reason =>
        console.error(`[ERROR]: Loading datasets in background: \n${reason}`)
      )
  }

  _clearInterval() {
    if (this.interval !== null) {
      clearInterval(this.interval)
    }
  }

  _onLoadDatasets(datasets) {
    this._updateLastUpdateDate()
    datasets = datasets.map(ds => {
      return { ...ds, models: 0 }
    })

    this.setState({ datasets, filteredDatasets: datasets }, () => {
      this._filterDatasets(datasets, this.state.searchValue)
      this._loadModels()
    })
  }

  _onLoadModels(models) {
    const datasets = this.state.datasets
    for (let i in models) {
      const dataset = datasets.find(
        ds => ds.dataset_name === models[i].dataset_name
      )
      if (!dataset || ["FAILED", "IN_PROGRESS"].includes(dataset.models))
        continue
      if (models[i].status === "FAILED") {
        dataset.models = "FAILED"
      } else if (models[i].status === "IN_PROGRESS") {
        dataset.models = "IN_PROGRESS"
      } else {
        dataset.models += 1
      }
    }
    this.setState({ models, datasets })
  }

  _onLoadDataError(error, type) {
    error.json().then(error => {
      const errorMessage = this.props.intl.formatMessage(
        { id: `page-dataset-load-${type}-ko` },
        { error: error.message }
      )
      console.error(`[ERROR] Loading ${type}: \n${error.message || error}`)
      message.error(errorMessage)
    })
  }

  _loadDatasets() {
    this._clearInterval()

    return getDatasets()
      .then(response => {
        this._onLoadDatasets(response)
        this._loadDatasetsPeriodically()
      })
      .catch(reason => this._onLoadDataError(reason, "datasets"))
      .finally(() => this.setState({ loading: false }))
  }

  _loadModels() {
    return (
      checkPermissions(["model:read"]) &&
      getModels()
        .then(response => {
          this._onLoadModels(response)
        })
        .catch(reason => this._onLoadDataError(reason, "models"))
        .finally(() => this.setState({ loading: false }))
    )
  }

  _getEmptyMessage(datasets) {
    return Array.isArray(datasets) &&
      datasets.length === 0 &&
      this.state.searchValue
      ? this.props.intl.formatMessage({
          id: "page-dataset-search-no-datasets",
        })
      : this.props.intl.formatMessage({ id: "page-dataset-no-datasets" })
  }

  _renderNewDatasetButton() {
    return (
      <Button
        className="knolar-button"
        type="primary"
        onClick={() =>
          navigate("/dataset/new", {
            state: {
              datasetNames: this.state.datasets.map(ds => ds.dataset_name),
              modelNames: this.state.models.map(m => m.model_name),
            },
          })
        }
      >
        {this.props.intl.formatMessage({
          id: "dataset-create-button",
        })}
      </Button>
    )
  }

  render() {
    const _thatIntl = this.props.intl

    return (
      <Template selected={["dataset", "dataset-index"]}>
        <Row>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <CustomBreadcrumb
              style={{ flexGrow: "1" }}
              crumbs={[_thatIntl.formatMessage({ id: "menu-datasets" })]}
            />
            {this.state.lastUpdate !== null ? (
              <div>
                {this.props.intl.formatMessage({ id: "last-update" })}:{" "}
                {this.state.lastUpdate}
              </div>
            ) : null}
          </div>
        </Row>
        <Row>
          <Col className="knolar-intro">
            {this.props.intl.formatMessage({ id: "menu-datasets" })}
          </Col>
        </Row>

        <div>
          {this.state.loading ? (
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Spin size="large" />
            </Row>
          ) : (
            <div className="content">
              <Row className="menu-datasets">
                <Collapse
                  defaultActiveKey={
                    this.state.datasets && this.state.datasets.length
                      ? null
                      : "1"
                  }
                  bordered={false}
                  accordion={true}
                >
                  <Panel
                    key="1"
                    header={
                      <Row style={{ fontWeight: "bold" }}>
                        {this.props.intl.formatMessage({
                          id: "datasets-info-header",
                        })}
                      </Row>
                    }
                  >
                    {
                      <div>
                        <Row className="dataset-steps" style={{}}>
                          {Object.entries(this.state.creationSteps).map(
                            ([key, value]) => (
                              <div key={key} className="dataset-step-card">
                                <div className="img-wrapper">
                                  <img
                                    className="dataset-step-icon"
                                    src={value.icon}
                                    alt="dataset-step-icon"
                                  />
                                </div>
                                <div className="dataset-step-title">
                                  {this.props.intl.formatMessage({
                                    id: value.title,
                                  })}
                                </div>
                                <div className="dataset-step-description">
                                  {this.props.intl.formatMessage({
                                    id: value.description,
                                  })}
                                </div>
                              </div>
                            )
                          )}
                        </Row>
                      </div>
                    }
                  </Panel>
                </Collapse>
              </Row>
              <Row style={{ margin: "2em 1em 1em 0" }}>
                <Col span={22}>
                  {this.state.datasets ? (
                    <Search
                      className="dataset-header-search knolar-input"
                      placeholder={this.props.intl.formatMessage({
                        id: "page-dataset-search-placeholder",
                      })}
                      onInput={this._onSearchInputChange}
                      // disabled={
                      //   !Datasets._isArrayWithValues(this.state.datasets)
                      // }
                    />
                  ) : (
                    this.props.intl.formatMessage({
                      id: "datasets-empty",
                    })
                  )}
                </Col>
                <Col span={2}>
                  {checkPermissions(["dataset:write"])
                    ? this._renderNewDatasetButton()
                    : null}
                </Col>
              </Row>
              <DatasetTable
                style={{ marginTop: "1em" }}
                datasets={this.state.filteredDatasets}
                emptyMessage={this._getEmptyMessage(
                  this.state.realTimeExtractors
                )}
                onDeleteDatasetClick={this._onDeleteDatasetClick}
                listModels={checkPermissions(["model:read"])}
              />
            </div>
          )}
        </div>
        <DatasetDeleteModal
          visible={this.state.deleteModalVisible}
          onOk={() => this._onDeleteConfirm()}
          onCancel={() => this._onDeleteCancel()}
        ></DatasetDeleteModal>
      </Template>
    )
  }
}

export default injectIntl(withAuth(Datasets))
