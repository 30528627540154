import React from "react"
import moment from "moment-timezone"
import { Table } from "antd"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import DatasetActionsCell from "./datasetActionsCell"
import { checkPermissions } from "../../services/auth/permissions/permissions"

import { datasetIsCreating } from "../../services/dataset"

// const LINK_COLOR = "#305ff9"

export class DatasetTable extends React.Component {
  get columns() {
    const _thatIntl = this.props.intl
    return [
      {
        title: _thatIntl.formatMessage({ id: "dataset-table-name" }),
        dataIndex: "dataset_name",
        key: "dataset_name",
        // width: "15%",
        sorter: (a, b) =>
          a["dataset_name"] < b["dataset_name"]
            ? -1
            : a["dataset_name"] > b["dataset_name"]
            ? 1
            : 0,
        sortDirections: ["ascend", "descend"],
        ellipsis: true,
        onCellClick: dataset => navigate(`/dataset/${dataset.dataset_name}`),
      },
      {
        title: _thatIntl.formatMessage({ id: "dataset-table-created-at" }),
        dataIndex: "created_at",
        key: "created_at",
        // width: "15%",
        sorter: (a, b) =>
          a["created_at"] < b["created_at"]
            ? -1
            : a["created_at"] > b["created_at"]
            ? 1
            : 0,
        sortDirections: ["ascend", "descend"],
        ellipsis: true,
        render: text => (
          <div>{text ? moment(text).format("YYYY-MM-DD hh:mm:ss") : "--"}</div>
        ),
        onCellClick: dataset => navigate(`/dataset/${dataset.dataset_name}`),
      },
      {
        title: _thatIntl.formatMessage({ id: "status" }),
        dataIndex: "status",
        key: "status",
        ellipsis: false,
        onCellClick: dataset => navigate(`/dataset/${dataset.dataset_name}`),
      },
      {
        title: _thatIntl.formatMessage({ id: "dataset-table-models" }),
        dataIndex: "models",
        key: "models",
        // width: 70,
        render: text => (
          <div className={text === "FAILED" ? "model-error" : ""}>
            {text === "FAILED" ? (
              _thatIntl.formatMessage({ id: "dataset-table-error-in-model" })
            ) : this.props.listModels ? (
              text
            ) : (
              <span
                title={_thatIntl.formatMessage({
                  id: "page-dataset-no-models-read-permission",
                })}
              >
                --
              </span>
            )}
          </div>
        ),
        ellipsis: false,
        onCellClick: dataset => navigate(`/dataset/${dataset.dataset_name}`),
      },
      {
        title: _thatIntl.formatMessage({ id: "actions" }),
        dataIndex: "actions",
        key: "actions",
        width: 120,
        // width: "12.5%",
        align: "center",
        render: (text, dataset) => (
          <DatasetActionsCell
            deleteDisabled={
              datasetIsCreating(dataset) ||
              !checkPermissions(["dataset:delete"])
            }
            onDeleteClick={() =>
              this.props.onDeleteDatasetClick(dataset.dataset_name)
            }
          />
        ),
        ellipsis: true,
      },
    ]
  }

  _onIngestStatusChange(ingest) {
    return () => {
      const { onIngestStatusChange } = this.props

      if (typeof onIngestStatusChange === "function") {
        onIngestStatusChange(ingest)
      }
    }
  }

  render() {
    return (
      <Table
        className="datasets-table"
        rowKey="dataset_name"
        showHeader={true}
        size="small"
        dataSource={this.props.datasets}
        columns={this.columns}
        locale={{ emptyText: this.props.emptyMessage || "" }}
      />
    )
  }
}

export default injectIntl(DatasetTable)
